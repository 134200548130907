import Vue from 'vue';
import VueRouter from 'vue-router';
import { findLast, isEmpty } from 'lodash';
import i18n from '@/i18n';
import store from '@/store';
import { findRouteRecursively, getFirstChildRoute, getRedirectPath, getRouteTitle } from '@/utils/route';
import { detect as detectBitrix24 } from '@/utils/bitrix24';
import { requestsMap } from '@/utils/request';

import BusinessDetailPage from '@/pages/_business';
import LoginPage from '@/pages/login';
import { getTopWindow } from '@/utils';
import { isFeatureEnabled } from '@/plugins/unleash';
import { getPayments } from '@/api/planning/fundingClaimPayment';

const isBitrix24Mode = detectBitrix24();

Vue.use(VueRouter);

const generatePath = (path) => {
    return (route) => {
        let processedPath = path;
        Object.entries(route.params).map(([key, value]) => {
            processedPath = processedPath.replace(`:${key}`, value);
        });
        const newOrigin = window.location.origin.replace('app.', 'm.');
        return newOrigin + processedPath;
    };
};

const routes = [
    {
        name: 'Home',
        path: '/',
        redirect: isBitrix24Mode ? '/bitrix24' : '/profile',
        meta: {
            hidden: true,
            mobilePath: generatePath('/')
        },
    },
    {
        name: 'Login',
        path: '/login',
        component: LoginPage,
        meta: {
            layout: 'auth',
            notRequiresAuth: true,
            title: i18n.t('title.login'),
            hidden: true,
            mobilePath: generatePath('/login')
        },
    },
    {
        name: 'InviteConfirm',
        path: '/invite-confirm',
        component: () => import('@/pages/invite-confirm'),
        meta: {
            layout: 'auth',
            notRequiresAuth: true,
            title: 'Завершение регистрации',
            hidden: true,
        },
    },
    {
        name: 'ResetPassword',
        path: '/reset-password/:email',
        component: () => import('@/pages/reset-password'),
        meta: {
            layout: 'auth',
            notRequiresAuth: true,
            title: 'Сброс пароля',
            hidden: true,
        },
    },
    {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: () => import('@/pages/forgot-password'),
        meta: {
            layout: 'auth',
            notRequiresAuth: true,
            title: 'Забыли пароль?',
            hidden: true,
        },
    },
    {
        name: 'ProfileDetail',
        path: '/profile',
        redirect: '/profile/businesses',
        component: () => import('@/pages/_profile'),
        meta: {
            hidden: true,
            navDrawer: true,
            layout: 'home',
        },
        beforeEnter: async (to, from, next) => {
            if (store.getters['business/currentId']) {
                await store.dispatch('business/resetCurrent');
            }

            next();
        },
        children: [
            {
                name: 'BusinessList',
                path: 'businesses',
                component: () => import('@/pages/businesses'),
                meta: {
                    title: i18n.t('title.businesses'),
                    mobilePath: generatePath('/businesses')
                },
            },
            {
                name: 'ProfileFundingClaims',
                path: 'funding-claims',
                component: () => import('@/pages/planning/funding-claims'),
                meta: {
                    title: 'Заявки на оплату',
                    perms: ['FUNDING_CLAIM_READ'],
                },
            },
            {
                name: 'ProfilePaymentCalendar',
                path: 'payment-calendar',
                component: () => import('@/pages/planning/payment-calendar'),
                meta: {
                    title: 'Платежный календарь',
                    perms: ['PAYMENT_CALENDAR_READ'],
                },
            },
            {
                name: 'ProfileTreasurerWorkspace',
                path: 'treasurer-workspace',
                component: () => import('@/pages/planning/funding-claim-paying'),
                meta: {
                    title: 'РМ казначея',
                    perms: ['TREASURER_WORKSPACE_READ'],
                },
            },
            {
                name: 'ProfileContracts',
                path: 'contracts',
                component: () => import('@/pages/document-flow/contracts-management.vue'),
                meta: {
                    title: 'Управление договорами',
                    menuSeparator: true,
                    perms: ['CONTRACT_READ'],
                    hidden: import.meta.env.MODE.includes('prod'),
                },
            },
            {
                name: 'ProfileIntegration',
                path: 'integrations',
                component: () => import('@/pages/profile/integrations'),
                meta: {
                    title: 'Настройка интеграций',
                    hasRouteAccess: () => (store.state.security.businessGroupMemberships ?? [])
                        .some(ms => (
                            ms.businessGroupId === store.getters.me?.primaryBusinessGroup?.id &&
                            (ms.isOwner ||
                                ms.permissions.some(p => ['integration'].includes(p))
                            ))),
                },
            },
            {
                name: 'ProfileManage',
                path: 'manage',
                component: () => import('@/pages/profile/profile-manage'),
                meta: {
                    title: 'Профиль и настройки',
                    hidden: true
                },
            },
            {
                name: 'ProfileAccess',
                path: 'access',
                component: () => import('@/pages/profile/access.vue'),
                meta: {
                    title: 'Управление доступом',
                    navDrawer: true,
                    hasRouteAccess: () => (store.state.security.businessGroupMemberships ?? [])
                        .some(ms => (
                            ms.businessGroupId === store.getters.me?.primaryBusinessGroup?.id &&
                            (ms.isOwner ||
                            ms.permissions.some(p => ['members_read', 'roles_read'].includes(p)))
                        )),
                },
                children: [
                    {
                        name: 'ProfileAccessUsers',
                        path: 'users',
                        component: () => import('@/pages/profile/access/users.vue'),
                        meta: {
                            navigationParent: 'ProfileDetail',
                            tabLabel: 'Пользователи',
                        },
                    },
                    {
                        name: 'ProfileAccessRoles',
                        path: 'roles',
                        component: () => import('@/pages/profile/access/roles.vue'),
                        meta: {
                            navigationParent: 'ProfileDetail',
                            tabLabel: 'Роли',
                        },
                    },
                ],
            },
            {
                name: 'ProfileAccessRolesDetail',
                path: 'access/roles/:roleId',
                component: () => import('@/pages/profile/access/roles/_id.vue'),
                meta: {
                    hidden: true,
                    drawerOptions: {
                        preventHistoryChange: true,
                    },
                },
            },
        ],
    },
    {
        name: 'BusinessDetail',
        path: '/businesses/:businessSlug',
        // redirect: '/businesses/:businessSlug/money',
        component: BusinessDetailPage,
        meta: {
            hidden: true,
            mobilePath: generatePath('/businesses/:businessSlug')
        },
        beforeEnter: async (to, from, next) => {
            const slug = to.params.businessSlug || '';
            if (!slug) {
                await next({ name: 'Home' });
                return;
            }

            const business = await store.dispatch('business/fetchSingleBySlug', { slug });
            if (!business) {
                await next({ name: 'Home' });
                return;
            }

            const requests = [store.dispatch('business/changeCurrent', business.id)];

            await Promise.all(requests);

            await store.dispatch('dadata/resetToken');
            if (business.id && !store.getters['dadata/hasToken']) {
                // noinspection ES6MissingAwait
                store.dispatch('dadata/fetchBusinessToken', { businessId: business.id }).then(token => {
                    if (token && !store.getters['dadata/hasLocation']) {
                        // noinspection ES6MissingAwait
                        store.dispatch('dadata/fetchLocationByIp');
                    }
                });
            }

            if (store.state.currency.total === 0) {
                // noinspection ES6MissingAwait
                store.dispatch('currency/fetchList');
            }

            const route = findRouteRecursively(to, routes);
            if (route) {
                const child = getFirstChildRoute(route);
                if (child) {
                    next(getRedirectPath(to, child));
                    return;
                }
            }

            next();
        },
        children: [
            {
                name: 'Money',
                path: 'money',
                // redirect: 'money/transactions',
                component: () => import('@/pages/money'),
                meta: {
                    title: i18n.t('title.money'),
                    perms: ['TRANSACTION_READ'],
                },
                children: [
                    {
                        name: 'MoneyTransactions',
                        path: 'transactions',
                        component: () => import('@/pages/money/transactions'),
                        meta: {
                            title: 'Операции',
                            perms: ['TRANSACTION_READ'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsImport',
                        path: 'transactions/import',
                        component: () => import('@/pages/money/transactions/import'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsImportDetail',
                        path: 'transactions/import/:importId',
                        component: () => import('@/pages/money/transactions/import-detail'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsImportedTransactions',
                        path: 'transactions/imported-transactions/:importId',
                        component: () => import('@/pages/money/transactions/imported-transactions.vue'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsVerification',
                        path: 'transactions/verification',
                        component: () => import('@/pages/money/transactions/verification'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsVerificationCreate',
                        path: 'transactions/verification/create',
                        component: () => import('@/pages/money/transactions/verification-create'),
                        meta: {
                            hidden: true,
                            drawerOptions: {
                                width: 640,
                            },
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsVerificationDetail',
                        path: 'transactions/verification/:id',
                        component: () => import('@/pages/money/transactions/verification-detail'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsCreate',
                        path: 'transactions/create',
                        component: () => import('@/pages/money/transactions/create'),
                        meta: {
                            title: 'Новая операция',
                            hidden: true,
                            perms: ['TRANSACTION_CREATE'],
                        },
                    },
                    {
                        name: 'MoneyTransactionsDetail',
                        path: 'transactions/:transactionId',
                        component: () => import('@/pages/money/transactions/_id'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Planning',
                path: 'planning',
                component: () => import('@/pages/planning'),
                meta: {
                    title: i18n.t('title.planning'),
                    navDrawer: true,
                    perms: [
                        'PAYMENT_CALENDAR_READ',
                        'FUNDING_CLAIM_READ',
                        'CASH_FLOW_BUDGET_REPORT_READ',
                    ],
                },
                children: [
                    {
                        name: 'PlanningFundingClaims',
                        path: 'funding-claims',
                        component: () => import('@/pages/planning/funding-claims'),
                        meta: {
                            title: i18n.t('title.fundingClaims'),
                            perms: ['FUNDING_CLAIM_READ'],
                            mobilePath: generatePath('/businesses/:businessSlug/funding-claims')
                        },
                    },
                    {
                        name: 'PlanningFundingClaimsCreate',
                        path: 'funding-claims/create',
                        component: () => import('@/pages/planning/funding-claims/create'),
                        meta: {
                            title: 'Новая заявка на оплату',
                            hidden: true,
                            perms: ['FUNDING_CLAIM_CREATE'],
                        },
                    },
                    {
                        name: 'PlanningFundingClaimsDetail',
                        path: 'funding-claims/:claimId',
                        component: () => import('@/pages/planning/funding-claims/_id'),
                        meta: {
                            hidden: true,
                            perms: ['FUNDING_CLAIM_READ'],
                            mobilePath: async (route) => {
                                let paymentId;

                                if (route.query.activePayment) {
                                    paymentId = route.query.activePayment;
                                } else if (router.app.$can('FUNDING_CLAIM_READ')) {
                                    const data = await getPayments({
                                        pageSize: 2,
                                        claim: route.params.claimId,
                                    });

                                    if ((data['hydra:member'] ?? []).length === 1) {
                                        paymentId = data['hydra:member'][0].id;
                                    }
                                }

                                if (paymentId) {
                                    return generatePath(`/businesses/:businessSlug/funding-claims/${paymentId}`)(route);
                                }

                                const params = new URLSearchParams({
                                    claim: route.params.claimId
                                });

                                return generatePath(`/businesses/:businessSlug/funding-claims?${params.toString()}`)(route);
                            }
                        },
                    },
                    {
                        name: 'PlanningPaymentCalendar',
                        path: 'payment-calendar',
                        component: () => import('@/pages/planning/payment-calendar'),
                        meta: {
                            title: 'Платёжный календарь',
                            perms: ['PAYMENT_CALENDAR_READ'],
                        },
                    },
                    {
                        name: 'PlanningPaymentCalendarPlanned',
                        path: 'payment-calendar/:date/planned',
                        component: () => import('@/pages/planning/payment-calendar/planned'),
                        meta: {
                            hidden: true,
                            perms: ['PAYMENT_CALENDAR_READ'],
                        },
                    },
                    {
                        name: 'PlanningPaymentCalendarActual',
                        path: 'payment-calendar/:date/actual',
                        component: () => import('@/pages/planning/payment-calendar/actual'),
                        meta: {
                            hidden: true,
                            perms: ['PAYMENT_CALENDAR_READ'],
                        },
                    },
                    {
                        name: 'ReportsCashFlowBudget',
                        path: 'cash-flow-budget',
                        component: () => import('@/pages/planning/cash-flow-budget.vue'),
                        meta: {
                            title: 'Бюджет движения денежных средств',
                            menuTitle: 'БДДС',
                            perms: ['CASH_FLOW_BUDGET_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsCashFlowBudgetDetail',
                        path: 'cash-flow-budget/details',
                        component: () => import('@/pages/planning/cash-flow-budget/details'),
                        meta: {
                            hidden: true,
                            perms: ['CASH_FLOW_BUDGET_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsCashFlowBudgetDetailPlan',
                        path: 'cash-flow-budget/:categoryId',
                        component: () => import('@/pages/planning/cash-flow-budget/_id.vue'),
                        meta: {
                            hidden: true,
                            perms: ['CASH_FLOW_BUDGET_REPORT_READ'],
                        },
                        drawerOptions: {
                            width: 800,
                        },
                    },
                    {
                        name: 'ReportsBdr',
                        path: 'bdr',
                        component: () => import('@/pages/reports/bdr'),
                        meta: {
                            title: 'Бюджет доходов и расходов',
                            menuTitle: 'БДР',
                            perms: ['REVENUES_EXPENDITURES_BUDGET_READ'],
                        },
                    },
                    {
                        name: 'ReportsBdrDetailPlan',
                        path: 'bdr/:categoryId',
                        component: () => import('@/pages/reports/bdr/_id.vue'),
                        meta: {
                            menuTitle: 'БДР',
                            perms: ['REVENUES_EXPENDITURES_BUDGET_READ'],
                        },
                    },
                    {
                        name: 'PlanningFundingClaimPaying',
                        path: 'funding-claim-paying',
                        component: () => import('@/pages/planning/funding-claim-paying'),
                        meta: {
                            title: 'РМ казначея',
                            group: ' ',
                            perms: ['TREASURER_WORKSPACE_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Reports',
                path: 'reports',
                // redirect: 'reports/money-movement',
                component: () => import('@/pages/reports'),
                meta: {
                    title: 'Отчеты',
                    navDrawer: true,
                    perms: [
                        'MONEY_MOVES_REPORT_READ',
                        'TREATMENT_TAXES_READ',
                        'LIABILITY_REPORT_READ',
                        'OPIU_REPORT_READ',
                        'REVENUES_EXPENDITURES_BUDGET_READ',
                    ],
                },
                children: [
                    {
                        name: 'ReportsMoneyMovement',
                        path: 'money-movement',
                        component: () => import('@/pages/reports/money-movement'),
                        meta: {
                            title: i18n.t('title.moneyMovement'),
                            perms: ['MONEY_MOVES_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsMoneyMovementDetail',
                        path: 'money-movement/details',
                        component: () => import('@/pages/reports/money-movement/details'),
                        meta: {
                            hidden: true,
                            perms: ['MONEY_MOVES_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsOpiu',
                        path: 'opiu',
                        component: () => import('@/pages/reports/opiu'),
                        meta: {
                            title: 'Отчёт о прибылях и убытках',
                            menuTitle: 'ОПиУ',
                            perms: ['OPIU_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsOpiuDetail',
                        path: 'opiu/:group',
                        component: () => import('@/pages/reports/opiu/_id'),
                        meta: {
                            hidden: true,
                            perms: ['OPIU_REPORT_READ'],
                            drawerOptions: {
                                preventHistoryChange: true,
                            },
                        },
                    },
                    {
                        name: 'ReportsTaxes',
                        path: 'taxes',
                        component: () => import('@/pages/reports/taxes'),
                        meta: {
                            title: 'Налоги',
                            menuTitle: 'Налоги',
                            perms: ['TREATMENT_TAXES_READ'],
                        },
                    },
                    {
                        name: 'ReportsTaxesDetail',
                        path: 'taxes/details',
                        component: () => import('@/pages/reports/taxes/details'),
                        meta: {
                            hidden: true,
                            perms: ['TREATMENT_TAXES_READ'],
                            drawerOptions: {
                                preventHistoryChange: true,
                            },
                        },
                    },
                    {
                        name: 'ReportsLiability',
                        path: 'liability',
                        component: () => import('@/pages/reports/liability'),
                        meta: {
                            title: 'Отчёт по обязательствам',
                            perms: ['LIABILITY_REPORT_READ'],
                        },
                    },
                    {
                        name: 'ReportsLiabilityDetail',
                        path: 'liability/detail',
                        component: () => import('@/pages/reports/liability/details'),
                        meta: {
                            hidden: true,
                            perms: ['LIABILITY_REPORT_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Contractors',
                path: 'contractors',
                // redirect: 'contractors/contractors-list',
                component: () => import('@/pages/contractors'),
                meta: {
                    title: i18n.t('title.contractors'),
                    navDrawer: true,
                    perms: [
                        'CONTRACTOR_ACCOUNT_READ',
                        'CONTRACTOR_READ',
                        'CONTRACTOR_REQUISITE_READ',
                        'DEAL_READ',
                        'INVOICE_READ',
                        'PURCHASE_READ',
                    ],
                },
                children: [
                    {
                        name: 'ContractorsContractorsList',
                        path: 'contractors',
                        component: () => import('@/pages/contractors/contractors'),
                        meta: {
                            title: 'Контрагенты',
                            perms: [
                                'CONTRACTOR_READ',
                                'CONTRACTOR_REQUISITE_READ',
                            ],
                        },
                    },
                    {
                        name: 'ContractorsContractorDetail',
                        path: 'contractors/:contractorId',
                        component: () => import('@/pages/contractors/contractors/_id'),
                        meta: {
                            perms: ['CONTRACTOR_READ'],
                        },
                    },
                    {
                        name: 'ContractorsReliabilityDetail',
                        path: 'reliability-contractor/detail/:ogrn',
                        component: () => import('@/pages/contractors/reliability-contractor/details'),
                        meta: {
                            hidden: true,
                            showPageHeader: false,
                            perms: ['CONTRACTOR_REQUISITE_READ'],
                            drawerOptions: {
                                width: 1400,
                            },
                        },
                    },
                    {
                        name: 'ContractorsReliabilityRiskLevel',
                        path: 'reliability-contractor/risk-level/:ogrn',
                        component: () => import('@/pages/contractors/reliability-contractor/risk-level.vue'),
                        meta: {
                            hidden: true,
                            showPageHeader: false,
                            perms: ['CONTRACTOR_REQUISITE_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'ContractorsDeals',
                        path: 'deals',
                        component: () => import('@/pages/contractors/deals'),
                        meta: {
                            title: i18n.t('title.deals'),
                            perms: ['DEAL_READ'],
                        },
                    },
                    {
                        name: 'ContractorsDealDetail',
                        path: 'deals/:dealId',
                        component: () => import('@/pages/contractors/deals/_id'),
                        meta: {
                            perms: ['DEAL_READ'],
                        },
                    },
                    {
                        name: 'ContractorsDealDetailPaid',
                        path: 'deals/:dealId/paid',
                        component: () => import('@/pages/contractors/deals/paid'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_READ'],
                        },
                    },
                    {
                        name: 'ContractorsContractorInvoices',
                        path: 'invoices',
                        component: () => import('@/pages/contractors/invoices'),
                        meta: {
                            title: 'Счета',
                            perms: ['INVOICE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsServicesList',
                        path: 'services',
                        component: () => import('@/pages/contractors/services'),
                        meta: {
                            title: 'Услуги',
                            perms: ['CONTRACTOR_SERVICE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsServiceCreate',
                        path: 'services/create',
                        component: () => import('@/pages/contractors/services/create'),
                        meta: {
                            title: 'Новая услуга',
                            hidden: true,
                            perms: ['CONTRACTOR_SERVICE_CREATE'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },

                    {
                        name: 'ContractorsServiceDetail',
                        path: 'services/:serviceId',
                        component: () => import('@/pages/contractors/services/_id'),
                        meta: {
                            hidden: true,
                            perms: ['CONTRACTOR_SERVICE_READ'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'ContractorsPurchases',
                        path: 'purchases',
                        component: () => import('@/pages/contractors/purchases'),
                        meta: {
                            title: 'Закупки',
                            group: ' ',
                            perms: ['PURCHASE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsPurchasesCreate',
                        path: 'purchases/create',
                        component: () => import('@/pages/contractors/purchases/create'),
                        meta: {
                            title: 'Новая закупка',
                            hidden: true,
                            perms: ['PURCHASE_CREATE'],
                        },
                    },
                    {
                        name: 'ContractorsPurchasesDetail',
                        path: 'purchases/:purchaseId',
                        component: () => import('@/pages/contractors/purchases/_id'),
                        meta: {
                            hidden: true,
                            perms: ['PURCHASE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsRequisiteCreate',
                        path: 'requisites/create',
                        component: () => import('@/pages/contractors/requisites/_id'),
                        meta: {
                            title: 'Новый реквизит',
                            hidden: true,
                            perms: ['CONTRACTOR_REQUISITE_CREATE'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'ContractorsRequisiteDetail',
                        path: 'requisites/:requisiteId',
                        component: () => import('@/pages/contractors/requisites/_id'),
                        meta: {
                            perms: ['CONTRACTOR_REQUISITE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsContractorInvoiceCreate',
                        path: 'invoices/create',
                        component: () => import('@/pages/contractors/invoices/create'),
                        meta: {
                            title: 'Новый счёт',
                            hidden: true,
                            perms: ['INVOICE_CREATE'],
                        },
                    },
                    {
                        name: 'ContractorsContractorInvoiceDetail',
                        path: 'invoices/:invoiceId',
                        component: () => import('@/pages/contractors/invoices/_id'),
                        meta: {
                            hidden: true,
                            perms: ['INVOICE_READ'],
                        },
                    },
                    {
                        name: 'ContractorsContractorInvoiceDetailPaid',
                        path: 'invoices/:invoiceId/paid',
                        component: () => import('@/pages/contractors/invoices/paid'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Salary',
                path: 'salary',
                component: () => import('@/pages/salary'),
                meta: {
                    title: 'Зарплата',
                    navDrawer: true,
                    perms: [
                        'ASSESSMENT_DOCUMENT_READ',
                        'INTEGRATION_READ',
                        'PAYMENT_READ',
                        'SALARY_REPORT_READ',
                        'EMPLOYEE_READ',
                        'PERSONNEL_DOCUMENT_READ',
                        'DEPARTMENT_READ',
                        'TIMESHEET_READ',
                        'DIVIDENDS_ALLOCATIONS_REPORT_READ',
                    ],
                },
                children: [
                    {
                        name: 'SalarySalaryReport',
                        path: 'salary-report',
                        component: () => import('@/pages/salary/salary-report'),
                        meta: {
                            title: 'Отчёт по ЗП',
                            perms: ['SALARY_REPORT_READ'],
                        },
                    },
                    {
                        name: 'SalarySalaryReportPaid',
                        path: 'salary-report/:employeeId/paid',
                        component: () => import('@/pages/salary/salary-report/paid'),
                        meta: {
                            hidden: true,
                            perms: ['SALARY_REPORT_READ'],
                        },
                    },
                    {
                        name: 'SalarySalaryReportUnlinked',
                        path: 'salary-report/:employeeId/unlinked',
                        component: () => import('@/pages/salary/salary-report/unlinked'),
                        meta: {
                            hidden: true,
                            perms: ['SALARY_REPORT_READ'],
                        },
                    },
                    {
                        name: 'SalaryAssessments',
                        path: 'assessments',
                        component: () => import('@/pages/salary/assessments'),
                        meta: {
                            title: 'Начисления за месяц',
                            perms: ['ASSESSMENT_DOCUMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryAssessmentsDetail',
                        path: 'assessments/:month/:departmentId',
                        component: () => import('@/pages/salary/assessments/_id'),
                        meta: {
                            hidden: true,
                            perms: ['ASSESSMENT_DOCUMENT_READ'],
                            drawerOptions: {
                                width: 1184,
                            },
                        },
                    },
                    {
                        name: 'SalaryEmployeeKpi',
                        path: 'kpi/:month/:departmentId/:employeeId',
                        component: () => import('@/pages/salary/kpi/_id'),
                        meta: {
                            hidden: true,
                            perms: ['KPI_UPDATE'],
                            drawerOptions: {
                                width: 1184,
                            },
                        },
                    },
                    {
                        name: 'SalaryAssessmentsTaxDetail',
                        path: 'tax/:employeeId',
                        component: () => import('@/pages/salary/assessments/tax-items/_id'),
                        meta: {
                            hidden: true,
                            perms: ['ASSESSMENT_DOCUMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryPayments',
                        path: 'payments',
                        component: () => import('@/pages/salary/payments'),
                        meta: {
                            title: 'Ведомости на выплаты',
                            perms: ['PAYMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryDividends',
                        path: 'dividends',
                        component: () => import('@/pages/salary/dividends'),
                        meta: {
                            title: 'Дивиденды',
                            perms: ['DIVIDENDS_ALLOCATIONS_REPORT_READ'],
                        },
                    },
                    {
                        name: 'SalaryDividendsDetail',
                        path: 'dividends/details',
                        component: () => import(/* webpackChunkName: "salary" */ '@/pages/salary/dividends/details.vue'),
                        meta: {
                            hidden: true,
                            perms: ['DIVIDENDS_ALLOCATIONS_REPORT_READ'],
                        },
                    },
                    {
                        name: 'SalaryPaymentsCreate',
                        path: 'payments/create',
                        component: () => import('@/pages/salary/payments/create'),
                        meta: {
                            title: 'Новая ведомость на выплату',
                            hidden: true,
                            perms: ['PAYMENT_CREATE'],
                            drawerOptions: {
                                width: 1200,
                            },
                        },
                    },
                    {
                        name: 'SalaryPaymentsDetail',
                        path: 'payments/:paymentId',
                        component: () => import('@/pages/salary/payments/_id'),
                        meta: {
                            hidden: true,
                            perms: ['PAYMENT_READ'],
                            drawerOptions: {
                                width: 1200,
                            },
                        },
                    },
                    {
                        name: 'SalaryPaymentsDocumentDetail',
                        path: 'payments/:paymentId/details',
                        component: () => import(/* webpackChunkName: "salary" */ '@/pages/salary/payments/details'),
                        meta: {
                            hidden: true,
                            perms: ['PAYMENT_READ'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                    },
                    {
                        name: 'SalaryEmployees',
                        path: 'employees',
                        component: () => import('@/pages/salary/employees'),
                        meta: {
                            title: i18n.t('title.employees'),
                            group: i18n.t('title.staff'),
                            perms: [
                                'EMPLOYEE_READ',
                            ],
                        },
                    },
                    {
                        name: 'SalaryEmployeeCreate',
                        path: 'employees/create',
                        component: () => import('@/pages/salary/employees/employees/create'),
                        meta: {
                            perms: ['EMPLOYEE_CREATE'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'SalaryEmployeeEdit',
                        path: 'employees/edit/:employeeId',
                        component: () => import('@/pages/salary/employees/employees/_id'),
                        meta: {
                            perms: ['EMPLOYEE_READ', 'EMPLOYEE_UPDATE'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'SalaryEmployeeDetail',
                        path: 'employees/:employeeId',
                        redirect: 'employees/:employeeId/overview',
                        component: () => import('@/pages/salary/employees/_id'),
                        meta: {
                            perms: ['EMPLOYEE_READ'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                        children: [
                            {
                                name: 'SalaryEmployeeDetailOverview',
                                path: 'overview',
                                component: () => import('@/pages/salary/employees/_id/overview'),
                                meta: {
                                    navigationParent: 'Salary',
                                    perms: [
                                        'EMPLOYEE_READ',
                                    ],
                                    drawerOptions: {
                                        width: 1100,
                                    },
                                },
                            },
                            {
                                name: 'SalaryEmployeeDetailPersonnelDocuments',
                                path: 'personnel-documents',
                                component: () => import('@/pages/salary/employees/_id/personnel-documents'),
                                meta: {
                                    navigationParent: 'Salary',
                                    perms: ['PERSONNEL_DOCUMENT_READ'],
                                },
                            },
                            {
                                name: 'SalaryEmployeeDetailAssessments',
                                path: 'assessments',
                                component: () => import('@/pages/salary/employees/_id/assessments'),
                                meta: {
                                    navigationParent: 'Salary',
                                    perms: ['ASSESSMENT_DOCUMENT_READ'],
                                },
                            },
                            {
                                name: 'SalaryEmployeeDetailPayments',
                                path: 'payments',
                                component: () => import('@/pages/salary/employees/_id/payments'),
                                meta: {
                                    navigationParent: 'Salary',
                                    perms: ['SALARY_REPORT_READ'],
                                },
                            },
                        ],
                    },
                    {
                        name: 'SalaryPersonnelDocuments',
                        path: 'personnel-documents',
                        component: () => import('@/pages/salary/personnel-documents'),
                        meta: {
                            title: i18n.t('title.staffingChanges'),
                            group: i18n.t('title.staff'),
                            perms: ['PERSONNEL_DOCUMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryPersonnelDocumentsCreate',
                        path: 'personnel-documents/create',
                        component: () => import('@/pages/salary/personnel-documents/create'),
                        meta: {
                            hidden: true,
                            perms: ['PERSONNEL_DOCUMENT_CREATE'],
                        },
                    },
                    {
                        name: 'SalaryPersonnelDocumentsDetail',
                        path: 'personnel-documents/:personnelDocumentId',
                        component: () => import('@/pages/salary/personnel-documents/_id'),
                        meta: {
                            hidden: true,
                            perms: ['PERSONNEL_DOCUMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryOrgchart',
                        path: 'orgchart',
                        component: () => import('@/pages/salary/orgchart'),
                        meta: {
                            title: i18n.t('title.orgchart'),
                            group: i18n.t('title.staff'),
                            perms: ['DEPARTMENT_READ'],
                        },
                    },
                    {
                        name: 'SalaryDepartmentCreate',
                        path: 'orgchart/create',
                        component: () => import('@/pages/salary/orgchart/create'),
                        meta: {
                            perms: ['DEPARTMENT_CREATE'],
                            drawerOptions: {
                                width: 850,
                            },
                        },
                    },
                    {
                        name: 'SalaryDepartmentDetail',
                        path: 'orgchart/:departmentId',
                        component: () => import('@/pages/salary/orgchart/_id'),
                        meta: {
                            perms: ['DEPARTMENT_READ', 'DEPARTMENT_UPDATE'],
                            drawerOptions: {
                                width: 850,
                            },
                        },
                    },
                    {
                        name: 'SalaryDepartmentRoleCreate',
                        path: 'orgchart/:departmentId/access-rights/create',
                        component: () => import('@/pages/settings/access-rights/create.vue'),
                        meta: {
                            hidden: true,
                            perms: ['DEPARTMENT_UPDATE']
                        },
                    },
                    {
                        name: 'SalaryDepartmentRoleDetail',
                        path: 'orgchart/:departmentId/access-rights/:roleId',
                        component: () => import('@/pages/settings/access-rights/_id'),
                        meta: {
                            perms: ['DEPARTMENT_UPDATE']
                        },
                    },
                    {
                        name: 'SalaryTimesheet',
                        path: 'timesheet',
                        component: () => import('@/pages/salary/timesheet'),
                        meta: {
                            title: i18n.t('title.timesheet'),
                            group: i18n.t('title.staff'),
                            perms: ['TIMESHEET_READ'],
                        },
                    },
                    {
                        name: 'SalaryOnecZup',
                        path: 'onec-zup',
                        component: () => import('@/pages/salary/onec-zup'),
                        meta: {
                            title: '1С: ЗУП',
                            group: ' ',
                            perms: ['INTEGRATION_READ'],
                        },
                    },
                    {
                        name: 'SalaryOnecZupDocumentDetail',
                        path: 'onec-zup/:zupDocumentId',
                        component: () => import('@/pages/salary/zup-documents/_id'),
                        meta: {
                            hidden: true,
                            perms: ['ONEC_ORGANIZATION_READ'],
                        },
                    },
                ],
            },
            {
                name: 'DocumentManagement',
                path: 'document-management',
                component: () => import('@/pages/document-flow.vue'),
                meta: {
                    title: 'Документооборот',
                    navDrawer: true,
                    perms: [
                        'CONTRACT_TYPE_READ',
                        'CONTRACT_LOCATION_READ',
                        'CONTRACT_READ',
                        'CONTRACT_TEMPLATE_READ',
                        'CONTRACT_NUMBERING_RULE_READ',
                    ],
                },
                children: [
                    {
                        name: 'DocumentManagementContracts',
                        path: 'contracts',
                        component: () => import('@/pages/document-flow/contracts-management.vue'),
                        meta: {
                            title: 'Управление договорами',
                            perms: ['CONTRACT_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementContractsCreate',
                        path: 'contracts/create',
                        component: () => import('@/pages/document-flow/contracts-management/create'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                            fullHeightLayout: true,
                            showPageHeader: false
                        },
                    },
                    {
                        name: 'DocumentManagementContractsDetail',
                        path: 'contracts/:contractId',
                        component: () => import('@/pages/document-flow/contracts-management/_id'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                            fullHeightLayout: true,
                            showPageHeader: false
                        },
                    },

                    {
                        name: 'DocumentManagementAmendmentsCreate',
                        path: 'amendments/create',
                        component: () => import('@/pages/document-flow/amendments/create.vue'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                            fullHeightLayout: true,
                            showPageHeader: false
                        },
                    },
                    {
                        name: 'DocumentManagementAmendmentsDetail',
                        path: 'amendments/:amendmentId',
                        component: () => import('@/pages/document-flow/amendments/_id'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                            fullHeightLayout: true,
                            showPageHeader: false
                        },
                    },

                    {
                        name: 'DocumentManagementContractsInternal',
                        path: 'contracts-internal',
                        component: () => import('@/pages/document-flow/contracts-internal-management.vue'),
                        meta: {
                            title: 'Внутренние документы',
                            menuSeparator: true,
                            perms: ['CONTRACT_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementContractsInternalCreate',
                        path: 'contracts-internal/create',
                        component: () => import('@/pages/document-flow/contracts-internal-management/create'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementContractsInternalDetail',
                        path: 'contracts-internal/:contractId',
                        component: () => import('@/pages/document-flow/contracts-internal-management/_id'),
                        meta: {
                            perms: ['CONTRACT_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementBusinessRequisiteCards',
                        path: 'business-requisite-cards',
                        component: () => import('@/pages/document-flow/business-requisite-cards.vue'),
                        meta: {
                            title: 'Карточки предприятий',
                            perms: ['CONTRACT_READ']
                        }
                    },
                    {
                        name: 'DocumentManagementContractTypes',
                        path: 'contract-types',
                        component: () => import('@/pages/document-flow/contract-type.vue'),
                        meta: {
                            title: 'Типы документов',
                            group: 'Настройки',
                            perms: ['CONTRACT_TYPE_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementContractTypesCreate',
                        path: 'contract-type/create',
                        component: () => import('@/pages/document-flow/contract-type/create'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_TYPE_READ'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'DocumentManagementContractTypesDetail',
                        path: 'contract-type/:itemId',
                        component: () => import('@/pages/document-flow/contract-type/_id'),
                        meta: {
                            hideInMenu: true,
                            perms: ['CONTRACT_TYPE_READ'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'DocumentManagementContractLocations',
                        path: 'contract-locations',
                        component: () => import('@/pages/document-flow/contract-location.vue'),
                        meta: {
                            title: 'Местонахождения',
                            group: 'Настройки',
                            perms: ['CONTRACT_LOCATION_READ'],
                        },
                    },
                    {
                        name: 'DocumentManagementContractLocationsCreate',
                        path: 'contract-location/create',
                        component: () => import('@/pages/document-flow/contract-location/create'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_LOCATION_READ'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'DocumentManagementContractLocationsDetail',
                        path: 'contract-location/:itemId',
                        component: () => import('@/pages/document-flow/contract-location/_id'),
                        meta: {
                            hideInMenu: true,
                            perms: ['CONTRACT_LOCATION_READ'],
                            drawerOptions: {
                                width: 800,
                            },
                        },
                    },
                    {
                        name: 'ContractCustomFields',
                        path: 'custom-fields',
                        component: () => import('@/pages/document-flow/contract-custom-fields.vue'),
                        meta: {
                            title: 'Пользовательские поля',
                            group: 'Настройки',
                            perms: ['CONTRACT_CUSTOM_FIELD_READ'],
                        },
                    },
                    {
                        name: 'ContractCustomFieldsCreate',
                        path: 'contract-custom-fields/create',
                        component: () => import('@/pages/document-flow/contract-custom-fields/create.vue'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_CUSTOM_FIELD_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'ContractCustomFieldsDetail',
                        path: 'contract-custom-fields/:itemId',
                        component: () => import('@/pages/document-flow/contract-custom-fields/_id.vue'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_CUSTOM_FIELD_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'ContractTemplates',
                        path: 'templates',
                        component: () => import('@/pages/document-flow/contract-templates.vue'),
                        meta: {
                            title: 'Шаблоны документов',
                            group: 'Настройки',
                            perms: ['CONTRACT_TEMPLATE_READ'],
                        },
                    },
                    {
                        name: 'ContractTemplatesCreate',
                        path: 'templates/create',
                        component: () => import('@/pages/document-flow/contract-templates/create.vue'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_TEMPLATE_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'ContractTemplatesDetail',
                        path: 'templates/:templateId',
                        component: () => import('@/pages/document-flow/contract-templates/_id.vue'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['CONTRACT_TEMPLATE_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'ContractSettingNumbering',
                        path: 'contract-setting-numbering',
                        component: () => import('@/pages/document-flow/contract-setting-numbering.vue'),
                        meta: {
                            title: 'Настройка нумерации',
                            menuTitle: 'Настройка нумерации',
                            group: 'Настройки',
                            perms: ['CONTRACT_NUMBERING_RULE_READ'],
                        },
                    },
                ],
            },

            {
                name: 'Loans',
                path: 'loans',
                component: () => import('@/pages/loans'),
                meta: {
                    title: 'Кредиты',
                    navDrawer: true,
                    perms: [
                        'LOAN_READ',
                    ],
                },
                children: [
                    {
                        name: 'ContractorLoansIn',
                        path: 'loans-in',
                        component: () => import('@/pages/loans/loans-in'),
                        meta: {
                            title: 'Полученные кредиты',
                            perms: ['LOAN_READ'],
                        },
                    },
                    {
                        name: 'ContractorLoansOut',
                        path: 'loans-out',
                        component: () => import('@/pages/loans/loans-out'),
                        meta: {
                            title: 'Выданные кредиты',
                            perms: ['LOAN_READ'],
                        },
                    },
                    {
                        name: 'ContractorLoansInDetail',
                        path: 'loans-in/:loanId', // Изъеб, чтобы в деталке кредита в меню отображался выбранный пункт меню
                        component: () => import('@/pages/loans/loans/_id'),
                        meta: {
                            hidden: true,
                            perms: ['LOAN_READ'],
                        },
                    },
                    {
                        name: 'ContractorLoansOutDetail',
                        path: 'loans-out/:loanId',
                        component: () => import('@/pages/loans/loans/_id'),
                        meta: {
                            hidden: true,
                            perms: ['LOAN_READ'],
                        },
                    },
                    {
                        name: 'ContractorLoansComparison',
                        path: 'comparison/:loanId',
                        component: () => import('@/pages/loans/loans/comparison'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_READ'],
                        },
                    },
                    {
                        name: 'ContractorLoanSettings',
                        path: 'settings',
                        component: () => import('@/pages/loans/settings'),
                        meta: {
                            title: 'Настройки кредитов',
                            perms: ['LOAN_ANALYTIC_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Possessions',
                path: 'possessions',
                component: () => import('@/pages/possessions'),
                meta: {
                    perms: [
                        'FIXED_ASSETS_CATEGORY_READ',
                        'FIXED_ASSETS_LOCATION_READ',
                        'FIXED_ASSETS_LIST_READ',
                        'PRODUCT_CATEGORY_READ',
                        'PRODUCT_LOCATION_READ',
                        'POSSESSION_DOCUMENT_READ',
                    ],
                    title: 'Имущество',
                    navDrawer: true,
                },
                children: [
                    {
                        name: 'FixedAssets',
                        path: 'fixed-assets',
                        component: () => import('@/pages/possessions/fixed-assets'),
                        meta: {
                            perms: ['FIXED_ASSETS_CATEGORY_READ', 'FIXED_ASSETS_LOCATION_READ', 'FIXED_ASSETS_LIST_READ'],
                            title: 'Основные средства',
                        },
                    },
                    {
                        name: 'FixedAssetsDetail',
                        path: 'fixed-assets/:fixedAssetId',
                        component: () => import('@/pages/possessions/fixed-assets/_id'),
                        meta: {
                            hidden: true,
                            perms: ['FIXED_ASSETS_LIST_READ'],
                        },
                    },
                    {
                        name: 'PossessionsCancelCreate',
                        path: 'cancel/create',
                        component: () => import('@/pages/possessions/fixed-assets/cancel/create'),
                        meta: {
                            title: 'Новое списание',
                            hidden: true,
                            perms: ['FIXED_ASSETS_LIST_READ'],
                            drawerOptions: {
                                width: 600,
                            },
                        },
                    },
                    {
                        name: 'PossessionsCancelDetail',
                        path: 'cancel/:fixedAssetId',
                        component: () => import('@/pages/possessions/fixed-assets/cancel/_id'),
                        meta: {
                            title: 'Списание',
                            hidden: true,
                            perms: ['FIXED_ASSETS_LIST_READ'],
                            drawerOptions: {
                                width: 600,
                            },
                        },
                    },
                    {
                        name: 'PossessionProduct',
                        path: 'possessions-product',
                        component: () => import('@/pages/possessions/products'),
                        meta: {
                            perms: ['PRODUCT_CATEGORY_READ', 'PRODUCT_LOCATION_READ'],
                            title: 'Товары',
                        },
                    },
                    {
                        name: 'PossessionProductCreate',
                        path: 'possessions-product/create',
                        component: () => import('@/pages/possessions/possession-product/create'),
                        meta: {
                            title: 'Новый товар',
                            hidden: true,
                            perms: ['POSSESSION_PRODUCT_CREATE'],
                            drawerOptions: {
                                width: 1250,
                            },
                        },
                    },
                    {
                        name: 'PossessionProductDetail',
                        path: 'possessions-product/:possessionsProductId',
                        component: () => import('@/pages/possessions/possession-product/_id'),
                        meta: {
                            hidden: true,
                            perms: ['POSSESSION_PRODUCT_READ'],
                            drawerOptions: {
                                width: 1250,
                            },
                        },
                    },
                    {
                        name: 'PossessionProductLocationCreate',
                        path: 'product-location/create',
                        component: () => import('@/pages/possessions/location/create'),
                        meta: {
                            title: 'Новое местоположение',
                            hidden: true,
                            perms: ['PRODUCT_LOCATION_CREATE'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'PossessionProductLocationDetail',
                        path: 'product-location/:locationId',
                        component: () => import('@/pages/possessions/location/_id'),
                        meta: {
                            hidden: true,
                            perms: ['PRODUCT_LOCATION_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'PossessionProductCategoryCreate',
                        path: 'product-category/create',
                        component: () => import('@/pages/possessions/category/create'),
                        meta: {
                            title: 'Новая категория',
                            hidden: true,
                            perms: ['PRODUCT_CATEGORY_CREATE'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'PossessionProductCategoryDetail',
                        path: 'product-category/:categoryId',
                        component: () => import('@/pages/possessions/category/_id'),
                        meta: {
                            hidden: true,
                            perms: ['PRODUCT_CATEGORY_READ'],
                            drawerOptions: {
                                width: 700,
                            },
                        },
                    },
                    {
                        name: 'PossessionDocument',
                        path: 'possession-document',
                        component: () => import('@/pages/possessions/possession-document.vue'),
                        meta: {
                            perms: ['POSSESSION_DOCUMENT_READ'],
                            title: 'Список закупок имущества',
                        },
                    },
                    {
                        name: 'ReceiptDocumentCreate',
                        path: 'possession-document/create',
                        component: () => import('@/pages/possessions/possession-document/create'),
                        meta: {
                            hideInMenu: true,
                            hidden: true,
                            perms: ['POSSESSION_DOCUMENT_CREATE'],
                        },
                    },
                    {
                        name: 'ReceiptDocumentDetail',
                        path: 'possession-document/:itemId',
                        component: () => import('@/pages/possessions/possession-document/_id'),
                        meta: {
                            hideInMenu: true,
                            perms: ['POSSESSION_DOCUMENT_READ'],
                        },
                    },
                ],
            },
            {
                name: 'Settings',
                path: 'settings',
                component: () => import('@/pages/settings'),
                meta: {
                    title: i18n.t('title.settings'),
                    navDrawer: true,
                    perms: [
                        'ACCESS_RIGHT_READ',
                        'ASSESSMENT_CATEGORY_READ',
                        'BUSINESS_READ',
                        'BUSINESS_ACTIVITY_READ',
                        'BUSINESS_REQUISITE_READ',
                        'DEAL_STAGE_READ',
                        'EMPLOYMENT_READ',
                        'INTEGRATION_READ',
                        'POSITION_READ',
                        'TRANSACTION_CATEGORY_READ',
                        'FUNDING_CLAIM_PAYMENT_STAGE_READ',
                        'BUSINESS_MONTH_CLOSE_DATES_READ',
                        'ANALYTIC_ENTITY_READ',
                        'WORKING_MODE_READ',
                        'CONTRACT_STAGE_READ',
                    ],
                },
                children: [
                    {
                        name: 'SettingsBusinessOptions',
                        path: 'business-options',
                        component: () => import('@/pages/settings/business-options'),
                        meta: {
                            title: 'Бизнес',
                            group: 'Общее',
                            perms: ['BUSINESS_UPDATE'],
                        },
                    },
                    {
                        name: 'SettingsBusinessRequisites',
                        path: 'business-requisites',
                        component: () => import('@/pages/settings/business-requisites'),
                        meta: {
                            title: i18n.t('title.businessRequisites'),
                            group: 'Общее',
                            perms: ['BUSINESS_REQUISITE_READ'],
                        },
                    },
                    {
                        name: 'SettingsBusinessRequisitesCreate',
                        path: 'business-requisites/create',
                        component: () => import('@/pages/settings/business-requisites/create'),
                        meta: {
                            title: 'Новый реквизит',
                            hidden: true,
                            perms: ['BUSINESS_REQUISITE_CREATE'],
                            drawerOptions: {
                                width: 880,
                            },
                        },
                    },
                    {
                        name: 'SettingsBusinessRequisitesDetail',
                        path: 'business-requisites/:requisiteId',
                        component: () => import('@/pages/settings/business-requisites/_id'),
                        meta: {
                            perms: ['BUSINESS_REQUISITE_READ'],
                        },
                    },
                    {
                        name: 'SettingsBusinessAccountGroups',
                        path: 'account-groups',
                        component: () => import('@/pages/settings/account-groups'),
                        meta: {
                            perms: ['ACCOUNT_READ'],
                            drawerOptions: {
                                width: 600,
                            },
                        },
                    },
                    {
                        name: 'SettingsBusinessAccountCreate',
                        path: 'accounts/create',
                        component: () => import('@/pages/settings/accounts/create'),
                        meta: {
                            title: 'Новый счёт бизнеса',
                            hidden: true,
                            perms: ['ACCOUNT_CREATE'],
                            drawerOptions: {
                                width: 880,
                            },
                        },
                    },
                    {
                        name: 'SettingsBusinessAccountDetail',
                        path: 'accounts/:accountId',
                        component: () => import('@/pages/settings/accounts/_id'),
                        meta: {
                            perms: ['ACCOUNT_READ'],
                        },
                    },
                    {
                        name: 'SettingsBusinessAccountIntegrationDetail',
                        path: 'accounts/:accountId/integration',
                        component: () => import('@/pages/settings/accounts/integration.vue'),
                        meta: {
                            perms: ['INTEGRATION_READ'],
                            hidden: true,
                            drawerOptions: {
                                width: 880
                            },
                        },
                    },
                    {
                        name: 'SettingsBusinessActivities',
                        path: 'activities',
                        component: () => import('@/pages/settings/activities'),
                        meta: {
                            title: i18n.t('title.activities'),
                            group: 'Общее',
                            perms: ['BUSINESS_ACTIVITY_READ'],
                        },
                    },
                    {
                        name: 'SettingsTransactionCategories',
                        path: 'accounting-items',
                        component: () => import('@/pages/settings/transaction-categories'),
                        meta: {
                            title: i18n.t('title.accountingItems'),
                            group: 'Общее',
                            perms: ['TRANSACTION_CATEGORY_READ'],
                        },
                    },

                    {
                        name: 'AccountingCategoryCreate',
                        path: 'accounting-item/create',
                        component: () => import('@/pages/settings/transaction-categories/category/create.vue'),
                        meta: {
                            title: 'Новая статья учёта',
                            hidden: true,
                            perms: ['TRANSACTION_CATEGORY_CREATE'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                    },
                    {
                        name: 'AccountingCategoryDetail',
                        path: 'accounting-item/:categoryId',
                        component: () => import('@/pages/settings/transaction-categories/category/_id.vue'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CATEGORY_READ'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                    },


                    {
                        name: 'TransactionCategoryFolderCreate',
                        path: 'accounting-folder/create',
                        component: () => import('@/pages/settings/transaction-categories/folder/create.vue'),
                        meta: {
                            title: 'Новая группа статей',
                            hidden: true,
                            perms: ['TRANSACTION_CATEGORY_CREATE'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                    },
                    {
                        name: 'TransactionCategoryFolderDetail',
                        path: 'accounting-folder/:itemId',
                        component: () => import('@/pages/settings/transaction-categories/folder/_id.vue'),
                        meta: {
                            hidden: true,
                            perms: ['TRANSACTION_CATEGORY_READ'],
                            drawerOptions: {
                                width: 1100,
                            },
                        },
                    },

                    {
                        name: 'SettingsDealStatuses',
                        path: 'deal-statuses',
                        component: () => import('@/pages/settings/deal-statuses'),
                        meta: {
                            title: 'Статусы сделок',
                            group: 'Общее',
                            perms: ['DEAL_STAGE_READ'],
                        },
                    },
                    {
                        name: 'SettingsContractorTypes',
                        path: 'contractor-types',
                        component: () => import('@/pages/settings/contractor-types'),
                        meta: {
                            title: 'Типы компаний',
                            group: 'Общее',
                            perms: ['CONTRACTOR_READ'],
                            businessFlag: 'enabledContractorTypes',
                        },
                    },
                    {
                        name: 'SettingsMonthClose',
                        path: 'month-close',
                        component: () => import('@/pages/settings/month-close'),
                        meta: {
                            title: 'Закрытие месяца',
                            group: 'Общее',
                            perms: ['BUSINESS_MONTH_CLOSE_DATES_READ'],
                        },
                    },
                    {
                        name: 'SettingsUserAnalytics',
                        path: 'user-analytics',
                        component: () => import('@/pages/settings/user-analytics'),
                        meta: {
                            menuTitle: 'Пользовательские аналитики',
                            title: 'Справочники аналитик',
                            group: 'Общее',
                            perms: ['ANALYTIC_ENTITY_READ'],
                        },
                    },
                    {
                        name: 'SettingsUserAnalyticsDetail',
                        path: 'user-analytics/:analyticId',
                        component: () => import('@/pages/settings/user-analytics/detail'),
                        meta: {
                            hidden: true,
                            title: 'Пользовательская аналитика',
                            group: 'Общее',
                            perms: ['ANALYTIC_ENTITY_READ'],
                        },
                    },
                    {
                        name: 'SettingsUserAnalyticItemsCreate',
                        path: 'user-analytic-items/create',
                        component: () => import('@/pages/settings/user-analytic-items/create'),
                        meta: {
                            title: 'Новая пользовательская аналитика',
                            hidden: true,
                            perms: ['ANALYTIC_ENTITY_ITEM_CREATE'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsUserAnalyticItemsDetail',
                        path: 'user-analytic-items/:itemId',
                        component: () => import('@/pages/settings/user-analytic-items/_id'),
                        meta: {
                            hidden: true,
                            perms: ['ANALYTIC_ENTITY_ITEM_READ'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsContractorTypeDetail',
                        path: 'contractor-types/:typeId',
                        component: () => import('@/pages/settings/contractor-types/_id'),
                        meta: {
                            hidden: true,
                            drawerOptions: {
                                width: 640,
                            },
                        },
                    },
                    {
                        name: 'SettingsFundingClaimStages',
                        path: 'funding-claim-stages',
                        component: () => import('@/pages/settings/funding-claim-stages'),
                        meta: {
                            title: 'Согласование заявок',
                            group: 'Планирование',
                            perms: ['FUNDING_CLAIM_PAYMENT_STAGE_READ', 'FUNDING_CLAIM_PAYMENT_STAGE_RULE_READ'],
                        },
                    },
                    {
                        name: 'SettingsFundingClaimStagesCreate',
                        path: 'funding-claim-stages/create',
                        component: () => import('@/pages/settings/funding-claim-stages/stage/create.vue'),
                        meta: {
                            title: 'Новый этап согласования',
                            hidden: true,
                            perms: ['FUNDING_CLAIM_PAYMENT_STAGE_CREATE'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsFundingClaimStagesDetail',
                        path: 'funding-claim-stages/stage/:stageId',
                        component: () => import('@/pages/settings/funding-claim-stages/stage/_id.vue'),
                        meta: {
                            perms: ['FUNDING_CLAIM_PAYMENT_STAGE_READ'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsFundingClaimStagesRuleCreate',
                        path: 'funding-claim-stages/rule/create',
                        component: () => import('@/pages/settings/funding-claim-stages/rule/create.vue'),
                        meta: {
                            title: 'Создание правила',
                            hidden: true,
                            perms: ['FUNDING_CLAIM_PAYMENT_STAGE_CREATE'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsFundingClaimStagesRuleDetail',
                        path: 'funding-claim-stages/rule/:ruleId',
                        component: () => import('@/pages/settings/funding-claim-stages/rule/_id.vue'),
                        meta: {
                            perms: ['FUNDING_CLAIM_PAYMENT_STAGE_READ'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsContractStages',
                        path: 'contract-stages',
                        component: () => import('@/pages/settings/contract-stages'),
                        meta: {
                            title: 'Согласование договоров',
                            group: 'Планирование',
                            perms: ['CONTRACT_STAGE_READ'],
                        },
                    },
                    {
                        name: 'SettingsContractStagesCreate',
                        path: 'contract-stages/create',
                        component: () => import('@/pages/settings/contract-stages/create.vue'),
                        meta: {
                            perms: ['CONTRACT_STAGE_CREATE'],
                            title: 'Новый этап согласования',
                            hidden: true,
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsContractStagesDetail',
                        path: 'contract-stages/:stageId',
                        component: () => import('@/pages/settings/contract-stages/_id.vue'),
                        meta: {
                            perms: ['CONTRACT_STAGE_READ'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsContractStagesRuleCreate',
                        path: 'contract-stages/rule/create',
                        component: () => import('@/pages/settings/contract-stages/rule/create.vue'),
                        meta: {
                            perms: ['CONTRACT_STAGE_RULE_CREATE'],
                            title: 'Создание правила',
                            hidden: true,
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsContractStagesRuleDetail',
                        path: 'contract-stages/rule/:ruleId',
                        component: () => import('@/pages/settings/contract-stages/rule/_id.vue'),
                        meta: {
                            perms: ['CONTRACT_STAGE_RULE_READ'],
                            drawerOptions: {
                                width: 860,
                            },
                        },
                    },
                    {
                        name: 'SettingsPositions',
                        path: 'positions',
                        component: () => import('@/pages/settings/positions'),
                        meta: {
                            title: i18n.t('title.positions'),
                            group: i18n.t('title.staff'),
                            perms: ['POSITION_READ'],
                        },
                    },
                    // {
                    //     name: 'SettingsEmployments',
                    //     path: 'employments',
                    //     component: () => import('@/pages/settings/employments'),
                    //     meta: {
                    //         title: i18n.t('title.employments'),
                    //         group: i18n.t('title.staff'),
                    //         perms: ['EMPLOYMENT_READ']
                    //     }
                    // },
                    {
                        name: 'SettingsWorkingModes',
                        path: 'working-modes',
                        component: () => import('@/pages/settings/working-modes'),
                        meta: {
                            title: i18n.t('title.workingModes'),
                            group: i18n.t('title.staff'),
                            perms: ['WORKING_MODE_READ'],
                        },
                    },
                    {
                        name: 'SettingsAssessmentCategories',
                        path: 'assessment-categories',
                        component: () => import('@/pages/settings/assessment-categories'),
                        meta: {
                            title: i18n.t('title.assessmentCategories'),
                            group: i18n.t('title.staff'),
                            perms: ['ASSESSMENT_CATEGORY_READ'],
                        },
                    },
                    {
                        name: 'SettingsSalary',
                        path: 'salary',
                        component: () => import('@/pages/settings/salary'),
                        meta: {
                            title: 'Зарплата',
                            group: 'Кадры',
                            perms: ['BUSINESS_UPDATE'],
                        },
                    },
                    {
                        name: 'SettingsKpi',
                        path: 'kpi',
                        component: () => import('@/pages/settings/kpi'),
                        meta: {
                            title: 'KPI',
                            group: 'Кадры',
                            perms: ['KPI_UPDATE'],
                        },
                    },
                    {
                        name: 'SettingsAccessRights',
                        path: 'access-rights',
                        component: () => import('@/pages/settings/access-rights'),
                        meta: {
                            title: 'Управление доступом',
                            group: i18n.t('title.other'),
                            perms: ['_'], // Псевдоатрибут, доступ только owner-у
                        },
                    },
                    {
                        name: 'SettingsAccessRightsCreate',
                        path: 'access-rights/create',
                        component: () => import('@/pages/settings/access-rights/create.vue'),
                        meta: {
                            hidden: true,
                            perms: ['_']
                        },
                    },
                    {
                        name: 'SettingsAccessRightsDetail',
                        path: 'access-rights/:roleId',
                        component: () => import('@/pages/settings/access-rights/_id'),
                        meta: {
                            perms: ['_']
                        },
                    },
                    {
                        name: 'SettingsIntegrations',
                        path: 'integrations',
                        component: () => import('@/pages/settings/integrations'),
                        meta: {
                            title: i18n.t('title.integrations'),
                            group: i18n.t('title.other'),
                            perms: ['INTEGRATION_READ'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        redirect: '/',
        meta: {
            notRequiresAuth: true,
            hidden: true,
        },
    },
];

if (isBitrix24Mode) {
    routes.push(...[
        {
            name: 'Bitrix24',
            path: '/bitrix24',
            redirect: '/bitrix24/funding-claims',
            component: () => import('@/pages/bitrix24'),
            meta: {
                hidden: true,
            },
            children: [
                {
                    name: 'Bitrix24FundingClaims',
                    path: 'funding-claims',
                    component: () => import('@/pages/planning/funding-claims'),
                    meta: {
                        title: 'Заявки на оплату',
                        perms: ['FUNDING_CLAIM_READ'],
                    },
                },
                {
                    name: 'Bitrix24Invoices',
                    path: 'invoices',
                    component: () => import('@/pages/contractors/invoices'),
                    meta: {
                        title: 'Счета',
                        perms: ['INVOICE_READ'],
                    },
                },
                {
                    name: 'Bitrix24PaymentCalendar',
                    path: 'payment-calendar',
                    component: () => import('@/pages/planning/payment-calendar'),
                    meta: {
                        title: 'Платежный календарь',
                        perms: ['PAYMENT_CALENDAR_READ'],
                    },
                },
                {
                    name: 'Bitrix24SalaryAssessments',
                    path: 'salary-assessments/by-business/:businessSlug',
                    component: () => import('@/pages/bitrix24/salary-assessments'),
                    meta: {
                        hidden: true,
                        showHeader: false,
                        showPageHeader: false,
                    },
                },
                {
                    name: 'Bitrix24SalaryAssessmentsDetail',
                    path: 'salary-assessments/:month/:departmentId',
                    component: () => import('@/pages/bitrix24/salary-assessments/_id'),
                    meta: {
                        hidden: true,
                        showHeader: false,
                        showPageHeader: false,
                    },
                },
            ],
        },
    ]);
}

const topWindow = getTopWindow();
const isIframe = topWindow !== window.self;

const router = new VueRouter({
    mode: isIframe ? 'abstract' : 'history',
    base: import.meta.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const featureName = findLast(to.matched ?? [], r => r.meta.feature)?.meta.feature;
    await store.dispatch('setAccessDenied', false);

    if (featureName && !isFeatureEnabled(featureName)) {
        return next('/');
    }

    // Отменяем все "висящие" запросы
    // TODO: Возможно нужно будет оставлять определенные запросы, нужно мониторить
    if (
        !to.matched?.some(r => r.name === from.name) &&
        !from.matched?.some(r => r.name === to.name)
    ) {
        for (const [config, controller] of requestsMap.entries()) {
            if (config.method === 'get') {
                controller.abort();
                requestsMap.delete(config);
            }
        }
    }

    const redirectToLogin = async () => {
        const query = {};
        if (to.name !== 'Login') {
            query.redirect = to.fullPath;
        }

        next({
            name: 'Login',
            query,
        });
    };

    const isAuthRequired = !to.matched.some(route => route.meta?.notRequiresAuth);
    if (isAuthRequired) {
        if (!store.getters['auth/isAuthenticated']) {
            await store.dispatch('auth/refreshToken');
        }

        if (!store.getters['auth/isAuthenticated']) {
            await redirectToLogin();
            return;
        }

        const isAuthenticated = store.getters['auth/isAuthenticated'];
        if (isAuthenticated) {
            const userId = store.getters['auth/userId'];
            if (userId) {
                const dispatches = [];
                if (!store.getters.me) {
                    dispatches.push(store.dispatch('fetchMe'));
                }
                if (isEmpty(store.state.security.permissions)) {
                    dispatches.push(store.dispatch('security/fetchPermissions', { userId }));
                }
                try {
                    await Promise.all(dispatches);
                } catch {
                    await redirectToLogin();
                }
            } else {
                // Можно будет убрать позже. Оставлено для обратной совместимости, если JWT без userId
                if (!store.getters.me) {
                    if (!await store.dispatch('fetchMe', {
                        params: {
                            with: ['members', 'adminRoleInBusinesses'],
                        },
                    })) {
                        await redirectToLogin();
                    }
                }
                if (isEmpty(store.state.security.permissions)) {
                    await store.dispatch('security/fetchPermissions', { userId: store.getters.me.id });
                }
            }
        }
    }

    const regex = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i;
    const isMobile = regex.test(navigator.userAgent);

    if (isMobile && to.meta.mobilePath) {
        window.location.href = await to.meta.mobilePath(to);
        return;
    }

    const route = findRouteRecursively(to, routes);
    if (route) {
        const child = getFirstChildRoute(route);
        if (child) {
            next(getRedirectPath(to, child));
            return;
        }
    }

    next();
});

router.afterEach(async (to, from) => {
    await store.dispatch('boot');

    // if (isIframe) {
    //     topWindow.history.replaceState({ iframe: to.fullPath }, null, `#${to.fullPath}`);
    // }

    const title = getRouteTitle(to);
    if (title) {
        document.title = title;
    }

    if (
        from.params.businessSlug &&
        from.params.businessSlug !== to.params.businessSlug
    ) {
        await store.dispatch('business/resetCurrent');
    }
});

export default router;
